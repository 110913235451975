import React, { useState, useEffect } from 'react';

import { Alert, AlertTitle } from '@material-ui/lab';
import { ErrorOutline, NewReleases } from '@material-ui/icons';
import { Divider, Link, Typography, makeStyles } from '@material-ui/core';
import { DOCS } from '../../utilities/helper/link-config';

const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    marginTop: {
        marginTop: theme.spacing(1)
    }
}));


const ShortLink = (props) => {
    const { link, title } = props;
    return (
        <Link underline="always" color="inherit" target="_blank" href={link}>
            {title}
        </Link>
    )
}

export default function UserRoleIntroBanner(props) {
    const classes = useStyles();

    return (
        <Alert severity="error" icon={<NewReleases />} className={classes.marginTop}>
            <AlertTitle><b>User Roles Now Available in nanoStream!</b></AlertTitle>
            With version 4, we introduced User Roles to enhance security for our customers. <br />
            As part of our <b>Summer Release 2024</b>, you can now add different team members to your nanoStream account, each with their own set of permissions. <br />
            Manage access for monitoring, analytics, or support without compromising control over your production streams.
            <br /><br />
            Switch to the <ShortLink link="https://dashboard.nanostream.cloud/" title="current dashboard (Version 4)" /> to leverage the full power of User Roles and discover all features and functions available through the dashboard and API.
            <br />
            <Divider className={classes.divider} />
            <Typography variant="h6">
                <b>Important:</b> This feature is not supported in dashboard v2.
            </Typography>
            Users with <b>nanoUser</b> or <b>nanoReadOnly</b> roles must use Version 4 to access their respective features.
            <br /><br />
            <b>Useful Resources</b>: <ShortLink link={`${DOCS}docs/user_roles#getting-started`} title="Getting Started" /> | <ShortLink link={`${DOCS}docs/user_roles#user-roles-in-nanostream-cloud`} title="User Roles in nanoStream Cloud" /> | <ShortLink link={`${DOCS}docs/user_roles#high-level-permission-overview`} title="High-level Permission Overview" /> | <ShortLink link={`${DOCS}docs/cloud-frontend-v3/Dashboard_User_Roles`} title="User Management in nanoStream Cloud Dashboard" />
        </Alert>
    )
}
